



































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaCommission from '@/components/media/Commission.vue';

export default Vue.extend({
  components: {
    MediaCommission,
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      list: 'business/commission/List',
    }),
    business(): ApiResource.Business {
      return this.detect(this.$route.params.VANITY);
    },
    commissions(): ApiResource.Commission[] {
      let list: ApiResource.Commission[] = this.list(this.business.id);

      const customerId = this.$route.query.customer_id;
      if (customerId) list = list.filter((c) => c.customer_id === customerId);

      const { status } = this.$route.query;
      if (status === 'open') list = list.filter((c) => ['requested', 'active', 'pending'].includes(c.status));
      else if (status === 'closed') list = list.filter((c) => ['rejected', 'cancelled', 'finished', 'completed'].includes(c.status));
      else if (status) list = list.filter((c) => c.status === status);

      function asc(col: string) {
        return (a: any, b: any) => a[col] - b[col];
      }
      function desc(col: string) {
        return (a: any, b: any) => b[col] - a[col];
      }

      const { order, direction } = this.$route.query;
      const orderStr = Array.isArray(order) ? '' : order;
      if (['created_at', 'updated_at'].includes(orderStr) && direction) {
        if (direction === 'asc') list = list.sort(asc(orderStr));
        else if (direction === 'desc') list = list.sort(desc(orderStr));
      }

      return list;
    },
  },
  watch: {
    'business.id': {
      immediate: true,
      handler() {
        this.fetch();
      },
    },
  },
  methods: {
    async fetch(limit = 100, offset = 0, order = 'created_at', direction = 'desc'): Promise<void> {
      await this.$store.dispatch('business/commission/List', {
        BUSINESS_ID: this.business.id,
        query: {
          limit, offset, order, direction,
        },
      });

      if (this.commissions.length === limit + offset) {
        this.fetch(limit, limit + offset);
      }
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
